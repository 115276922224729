.plans-container{
    display: flex;
    flex-direction: column;
    padding: 0 2rem ;
}

.plans-header{
    color: white;
    gap: 1rem;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    display: flex;
    justify-content: center;
    margin-top: -3rem;
}

.p-blur-1{
    width: 32rem;
    height: 23rem;
    left: 0rem;
}

.p-blur-2{
    width: 32rem;
    height: 23rem;
    right: 0rem;
}

.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: 4rem;
}

.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem;
    width: 15rem;
    padding: 2rem;
}

.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan:nth-child(2){
    transform: scale(1.1);
    background-image: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%) ;
}

.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img{
    width: 1.4rem;
}

.plan:nth-child(2)>svg{
    fill: white;
}

.plan:nth-child(2)>button{
    color: var(--orange);
}



@media screen and (max-width: 768px) {

    .plans-header{
        flex-direction: column;
        font-size: xx-large;
       text-align: center;
    }

    .plans{
        flex-direction: column;
        margin-top: -1.5rem;
    }

    .plans>:nth-child(2){
        transform: none;
    }

    .plans{
        transform: scale(0.9);
    }


}
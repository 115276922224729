.testimonials-container{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.t-left{
    display: flex;
    flex: 1 ;
    flex-direction: column;
    gap: 2rem;
    text-transform: uppercase;
    color: white;
}

.t-left>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}

.t-left>:nth-child(2),.t-left>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.t-left>:nth-child(4){
    text-transform: none;
    text-align: justify;
    line-height: 40px;
    letter-spacing: 2px;
}

.t-right{
    display: flex;
    flex: 1;
    position: relative;
}

.t-right>img{
    position:absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.t-right>:nth-child(1){
    width: 17rem;
    height: 20rem;
    background-color: transparent;
    border: 4px solid var(--orange);
    right: 10rem;
    top: 0.5rem;
    position: absolute;
}

.t-right>:nth-child(2){
    width: 17rem;
    height: 20rem;
    background-image: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%) ;
    right: 5.7rem;
    top: 4rem;
    position: absolute;
}

.arrow{
    height: 1.8rem;
    width: 1.8rem;
    display: flex;
    gap: 1rem;
    bottom: 2rem;
    left: 4rem;
    position: absolute;
    cursor: pointer;
}

.arrow>img{
    height: 1.8rem;
    width: 1.8rem;
}

@media screen and (max-width: 768px) {
    .testimonials-container{
        margin-top: -2rem;
        flex-direction: column;
    }

    .t-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .t-right>div{
        display: none;
        position: relative;
    }

    .t-right>img{
        top: 0;
        right: 0;
        position: relative;
    }

    .t-right>:last-child{
        display: block;
        left: 0;
        bottom: 0;
        width: 30%;
    }
}
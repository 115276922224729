.programs{
    display: flex;
    flex-direction: column;
    padding: 0 2rem ;
}

.program-header{
    color: white;
    gap: 5rem;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    display: flex;
    justify-content: center;
}

.program-detail{
    display: flex;
    gap: 1rem;
}

.program-card{
    background-color: gray;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    padding: 2rem;
    color: white;
    justify-content: space-between;
    margin-top: 1.6rem;
}

.program-card>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}

.program-card>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.program-card>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}

.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.join-now>img{
    width: 1.3rem;
}

.program-card:hover{
    background-image: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%) ;
    cursor: pointer;
    scale: 0.9;
}

@media screen and (max-width: 768px) {
    .program-header{
        flex-direction: column;
        gap: 1rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    
    .program-detail{
        flex-direction: column;
    }
}
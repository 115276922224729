.join{
    display: flex;
    gap: 10rem;
    flex: 1 1;
    padding: 0 2rem;
}

.j-left{
    flex: 1;
    gap: 1rem;
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    font-weight: bold;

}
.j-left>hr{
    color: var(--orange);
    border: 2px solid;
    width: 15%;
    position: absolute;
    margin-top: -0.7rem;
    border-radius: 25%;
}

.j-left>div{
    display: flex;
    gap: 1rem;
}

.j-right{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1.7rem  2rem;
    border-radius: 5px;
}

.container>input{
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--lightgray);
}

::placeholder{
    color: var(--lightgray);
    padding: 6px;
    border-bottom: 1px solid var(--lightgray);
}

.j-btn{
    background-color: var(--orange);
    color: white;
}

@media screen and (max-width: 768px) {
    .join{
        flex-direction: column;
        gap: 1rem;
    }

    .j-left{
        font-size: xx-large;
        flex-direction: column;
    }

    .j-right{
        transform: scale(0.8);
    }
}
.hero{
    display: flex;
    justify-content: space-between;
}

.left{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.right{
    flex: 1;
    background-color: var(--orange);
    position: relative;
}

.the-ad{
    margin-top: 4rem;
    background-color: #363d42;
    width: fit-content;
    border-radius: 4rem;
    text-transform: uppercase;
    padding: 18px 12px;
    color: white;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.the-ad>span{
    z-index: 2;
}

.the-ad>div{
    position: absolute;
    background-color: var(--orange);
    height: 80%;
    width: 5.5rem;
    border-radius: 3.5rem;
    z-index: 1;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    color: white;
    font-size: 4.5rem;
    font-weight: bold;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.hero-btn{
    display: flex;
    gap: 1rem;
}

.hero-btn>:nth-child(1){
    background-color: var(--orange);
    color: white;
    width: 8rem;
}

.hero-btn>:nth-child(2){
    background-color: transparent;
    color: white;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right>.btn{
    position:absolute;
    top: 2rem;
    right: 3rem;
    color: black;
}

.heart-rate>img{
    width: 2rem;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 9px;
    top: 7rem;
    right: 4rem;
    position: absolute;
}

.heart-rate>:nth-child(2){
    color: var(--gray);
}

.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}

.hero-image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
}

.hero-image-back{
    position: absolute;
    z-index: -1;
    top: 4rem;
    right: 20rem;
    width: 15rem;
}

.calories{
    display: flex;
    gap: 2rem;
    border-radius: 5px;
    background-color: var(--caloryCard);
    width: 15rem;
    padding: 1rem;
    position: absolute;
    top: 32rem;
    right: 28rem;
}

.calories>img{
    width: 3rem;
}

.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories>div>:nth-child(1){
    color: var(--gray);
}

.calories>div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.h-blur{
    width:32rem;
    height:35rem;
    left:0,
}

@media screen and (max-width: 768px) 
{
    .hero{
        flex-direction: column;
    }

    .h-blur{
        width: 12rem;
    }

    .the-ad{
        margin-top: 0;
        font-size: small;
        align-items: center;
    }

    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }

    .hero-text > div:nth-child(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .hero-btn{
        justify-content: center;
    }

    .figures{
        justify-content: center;
        align-items: center;
    }

    .figures>div>span:nth-of-type(1){
        font-size: large;
    }

    .figures>div>span:nth-of-type(2){
        font-size: small;
    }

    .right{
        background:none;
        position: relative;
    }

    .heart-rate{
        left: 1rem;
        top: 2rem;
    }

    .calories{
        position: relative;
        top: 6rem;
        left: 1.4rem;
        margin-bottom: 3rem;
        border: 1px solid black;
    }

    .calories>img{
        width: 2rem;
    }

    .hero-image{
        position: relative;
        width: 15rem;
        align-items: center;
        left: 8rem;
        top: 5rem;
    }

    .hero-image-back{
        width: 15rem;
        top: 0rem;
        left: 2rem;
    }
}